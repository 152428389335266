import Link from 'next/link';
import React, { FC } from 'react';
import { privacyPolicyRoute, termsAndConditionsRoute } from '../../constants/router';
import { LogoIcon } from '../icons/Logo/Logo';
import { LinkXS } from '@formify/frontend-components';
import { TextS } from '@formify/frontend-components';
import {
    StaticPageFooter,
    FooterContactArea,
    FooterCopyrightArea,
    FooterFollowArea,
    FooterNewsletterArea,
    FooterTermsArea,
    StaticPageFooterGrid,
    FooterHeader,
} from './Footer.styled';
import { useEnv } from '../../hooks/useEnv';
import { Tr } from '../Translations/Tr';

export const Footer: FC = () => {
    const cookiePolicyRoute = useEnv('COOKIE_POLICY_URL') ?? '';

    return (
        <StaticPageFooter data-test="footer">
            <StaticPageFooterGrid>
                <FooterNewsletterArea>
                    <div>
                        <LogoIcon width={171} />
                    </div>
                </FooterNewsletterArea>

                <FooterContactArea>
                    <FooterHeader>
                        <Tr labelKey="footer.contactTitle" defaultValue="Contact" />
                    </FooterHeader>
                    <TextS>
                        <p>
                            Formify BV
                            <br />
                            Wibautstraat 131D
                            <br />
                            1091GL Amsterdam
                            <br />
                            The Netherlands
                        </p>
                        <br />
                        <p>
                            P: +31(0)20&nbsp;225&nbsp;94&nbsp;49
                            <br />
                            E: info@formify.com
                        </p>
                    </TextS>
                </FooterContactArea>

                <FooterFollowArea>
                    <FooterHeader>
                        <Tr labelKey="footer.followUs" defaultValue="Follow us" />
                    </FooterHeader>
                    <LinkXS>
                        <a
                            data-test-id="instagram-link"
                            href="https://www.instagram.com/formify.closets"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            INSTAGRAM
                        </a>
                        {/* <br /> <br />
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                                LINKEDIN
                            </a> */}
                    </LinkXS>
                </FooterFollowArea>

                <FooterCopyrightArea>
                    <TextS>
                        &copy; 2022 by Formify.
                        <Tr labelKey="footer.allRightReserved." defaultValue=" All right reserved." />
                    </TextS>
                </FooterCopyrightArea>

                <FooterTermsArea>
                    <LinkXS>
                        <Link href={termsAndConditionsRoute}>
                            <Tr labelKey="footer.termsAndConditions" defaultValue="TERMS & CONDITIONS" />
                        </Link>
                        <br />
                        <br />
                        <Link href={privacyPolicyRoute}>
                            <Tr labelKey="footer.privacy" defaultValue="PRIVACY POLICY" />
                        </Link>
                        <br />
                        <br />
                        <Link
                            href={cookiePolicyRoute}
                            target={cookiePolicyRoute.startsWith('http') ? '_blank' : undefined}
                        >
                            <Tr labelKey="footer.cookie" defaultValue="COOKIE POLICY" />
                        </Link>
                    </LinkXS>
                </FooterTermsArea>
            </StaticPageFooterGrid>
        </StaticPageFooter>
    );
};
